



























































































































































































































.horizontal_scroll {
  overflow-x: auto;
  & > table {
    width: var(--scrollWidth);
  }
  @media screen and( max-width :1279px ) {
    overflow-x: visible;
  }
}
