












/* 리스트 table */
.tbl_list::v-deep table {
  width: 100%;
}
.tbl_list::v-deep thead th:first-child,
.tbl_list::v-deep tbody td:first-child {
  padding-left: 8px;
}
.tbl_list::v-deep thead th:last-child,
.tbl_list::v-deep tbody td:last-child {
  padding-right: 8px;
}
.tbl_list::v-deep tbody td {
  padding: 4px;
  border-bottom: 1px solid #e1e1e1;
  text-align: center;
  word-break: break-all;
}
.tbl_list::v-deep tbody td .link_subject {
  display: block;
  width: 100%;
  text-align: left;
  text-decoration: underline;
}
.tbl_list::v-deep tbody td .link_point {
  color: #5551ce;
}
.tbl_list::v-deep tbody td .link_bold {
  font-weight: bold;
}
/* 리스트 table_hover */
.tbl_list::v-deep tbody .tr_hover td,
.tbl_list::v-deep tbody tr:hover td {
  background-color: #fbfbfb;
}
.tbl_list::v-deep tbody tr:hover td .link_subject {
  color: #5551ce;
}
/* 리스트 table_active */
.tbl_list::v-deep tbody .tr_select td {
  border-bottom-color: #5551ce;
  background: #fbfbfb url("~@/assets/images/bg_table_active.png") repeat-x;
  background-size: 1px 1px;
}
.tbl_list::v-deep tbody .tr_select td:first-child {
  border-left: 1px solid #5551ce;
}
.tbl_list::v-deep tbody .tr_select td:last-child {
  border-right: 1px solid #5551ce;
}
.tbl_list::v-deep tbody .tr_select .link_subject {
  color: #5551ce;
}
.tbl_list::v-deep tbody .tr_select + .tr_select td {
  background: #fbfbfb;
}
/* 리스티 빈타입 */
.tbl_list::v-deep .td_empty {
  overflow: hidden;
  line-height: 32px;
}
.tbl_list::v-deep .td_preline {
  white-space: pre-line;
}
/* 노호버타입 */
.tbl_nohover::v-deep tbody tr:hover td {
  background-color: inherit;
}
.tbl_nohover::v-deep tbody tr:hover td .link_subject {
  color: inherit;
}
/* 리스트 사이즈 별 */
.tbl_list_sm th,
.tbl_list_md th {
  padding: 7px 4px;
}
.tbl_list_sm::v-deep thead th:first-child,
.tbl_list_sm::v-deep tbody td:first-child,
.tbl_list_md::v-deep thead th:first-child,
.tbl_list_md::v-deep tbody td:first-child {
  padding-left: 8px;
}
.tbl_list_sm::v-deep thead th:last-child,
.tbl_list_sm::v-deep tbody td:last-child,
.tbl_list_md::v-deep thead th:last-child,
.tbl_list_md::v-deep tbody td:last-child {
  padding-right: 8px;
}

.tbl_list_sm::v-deep tbody td {
  height: 19px;
  padding: 0 4px;
}
.tbl_list_md::v-deep tbody td {
  height: 19px;
  padding: 4px 4px;
}

.tbl_list_12 th {
  padding: 8px 6px;
}
.tbl_list_12::v-deep tbody td {
  padding: 8px 6px;
}

.group_form .tbl_list {
  display: inline-block;
}
