
































































































.box_filter {
  display: table;
  width: 100%;
  border-top: 2px solid #222;
  border-bottom: 1px solid #d8d8d8;
}
/* .box_filter:after{display:block;visibility:hidden;height:0;font-size:0;clear:both;content:''} */
.box_filter::v-deep dl {
  display: table-cell;
  position: relative;
  padding: 8px 48px 16px 32px;
  padding-left: 152px;
  vertical-align: top;
}
.box_filter::v-deep dl:only-child {
  padding-right: 32px;
}
.box_filter::v-deep dl:first-child dt:first-child + dd {
  display: inline-block;
  vertical-align: top;
}
.box_filter::v-deep dl + dl {
  padding: 8px 32px 16px 48px;
  padding-left: 168px;
  border-left: 1px solid #e1e1e1;
}
.box_filter::v-deep dl + dl dt {
  left: 48px;
}
.box_filter::v-deep dt {
  position: absolute;
  left: 32px;
  width: 104px;
  height: 32px;
  padding: 8px 8px 0 0;
  font-size: 12px;
  line-height: 32px;
  color: #222;
}
.box_filter::v-deep dd {
  padding-top: 8px;
}
.box_filter::v-deep dd > .check_comm,
.box_filter::v-deep dd > .radio_comm {
  padding-top: 7px;
  padding-bottom: 7px;
}
.box_filter::v-deep dd .group_form .group_radio + .tf_comm {
  margin-left: 32px;
}
.box_filter::v-deep .row_pack .group_radio + .tf_comm,
.box_filter::v-deep .row_pack .group_radio + .area_search {
  margin-left: 32px;
}
.box_filter::v-deep .row_pack + .row_pack {
  margin-top: 16px;
}
.box_filter > .group_form {
  padding: 17px 24px;
}
.box_filter::v-deep .group_form .radio_comm + .tf_comm,
.box_filter::v-deep .group_form .radio_comm + .area_search {
  margin-left: 32px;
}
.box_filter::v-deep .group_form + .group_form {
  margin-top: 16px;
}
.box_filter + .group_btn {
  padding: 12px 0 24px;
  text-align: center;
}
.box_filter + .group_btn .btn_large {
  width: 120px;
  padding-left: 0;
  padding-right: 0;
}
.box_filter + .group_btn button + button {
  margin-left: 8px;
}
.box_filter + .group_btn button + .btn_reset {
  margin-top: 4px;
}
.box_filter:only-child {
  margin-bottom: 32px;
}
/* 상세 페이지 내 필터바 */
.area_view .area_filter .box_filter {
  border-top: 1px solid #222;
}
.area_view .area_filter .box_filter dl {
  padding: 8px 32px 24px 152px;
}
.area_view .area_filter + .tbl_comm {
  margin-top: 32px;
  border-top: 1px solid #e1e1e1;
}
